import React from 'react'
import './register.css'

const Register = () => {
  return (
    <div className='register'>
        <div className="center">
            <p>Thank you for registering for The Creator Block Online<br/> Click this button to join the Whatsapp Group</p>
            <h5>GetWorldWide</h5>
        </div>
    </div>
  )
}

export default Register