import React from 'react'
import './index.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Register from './pages/Register/Register';

const App = () => {
  return (
    <div className='App'>
      <Routes>
        <Route path="*" element={<Home/>} />
        <Route path="/registered" element={<Register/>} />
      </Routes>
    </div>
  )
}

export default App