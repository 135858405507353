import React from 'react'
import { useRef } from 'react';
import './home.css'
import 'bootstrap/dist/css/bootstrap.css';
import emailjs from 'emailjs-com'
import  {Link, Route, Routes} from 'react-router-dom';
import Register from '../Register/Register';


const Home = () => {
    const form = useRef();

    const register = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_h5n7iob', 'template_2ptqdeb', form.current, 'nF2jxCwwnqZBRjblx');
        e.target.reset();
          
    }; 

    const showRegister = () => {
        window.alert("Registration Sent! For payment via bank transfer, kindly send a screenshot of the transaction details to 08105973386 | Account Name: Getworldwide | Bank Name: Safe Heaven MFB | Account Number: 8012623934 | Amount: 50,000 Naira (Early Bird) / 80,000 Naira (after May 22, 2024) | Click OK to Join The Creator Block by GetWorldWide Online Edition Whatsapp Group");
        var win = window.open("https://chat.whatsapp.com/HJUboH4cSTRIexI7TGwoe6", '_blank');
        win.focus();
    
      };
  return (
    <div className='home'>
        <div className="left">
            <h5>GETWORLDWIDE</h5>
            <h1>The<br/>Creator<br/>Block</h1>
            <p>WITH VICTOR GIWA</p>
            <div className="foot"></div>
            <div className="footstuff">
                <p>BEGINNERS CLASS ON PHOTOGRAPHY, VIDEOGRAPHY AND STORYTELLING- ONLINE EDITION!<br/><span><a href='https://wa.link/4z92n7'>Contact for enquiry 📞</a></span></p>
            </div>
        </div>
        <div className="right">
            <div className="contact">
                <a href='https://wa.link/4z92n7'><p>CONTACT</p></a>
            </div>
            <h5>Register for The Creator's Block</h5>
            <form ref={form} onSubmit={register} action='/registered' method='POST'>
                <label for="name">Full Name</label><br/>
                <input type="text" id="name" name="name" required/><br/>
                <label for="email">Email</label><br/>
                <input type="text" id="email" name="email" required/><br/>
                <label for="number">Phone Number</label><br/>
                <input type="phone" id="number" name="number" required/><br/>
                <label for="gender">Gender</label><br/>
                <input type="text" id="gender" name="gender" required/><br/>
                <label for="why">Why do you want to join this photo and videography creative class?</label><br/>
                <input type="text" id="why" name="why" required/><br/>
                <label for="experience">Do you have any previous experience in photography or videography?</label><br/>
                <input type="text" id="experience" name="experience" required/><br/>
                <label for="improve">What specific skills or techniques are you hoping to learn and improve<br/> upon during the class?</label><br/>
                <input type="text" id="improve" name="improve" required/><br/>
                <label for="prefer">Do you have a preferred style or them you want to explore in your <br/>creative work?</label><br/>
                <input type="text" id="prefer" name="prefer" required/><br/>
                <label for="interest">Are you more interested in still photography, videography or both?</label><br/>
                <input type="text" id="interest" name="interest" required/><br/>
                <label for="challenge">Are there any particular challenges or obstacles you face in your<br/>creative process that you hope to address in this class?</label><br/>
                <input type="text" id="challenge" name="challenge" required/><br/>
                <label for="goals">Do you have any specific goals you want to achieve through attending<br/> this class?</label><br/>
                <input type="text" id="goals" name="goals" required/><br/>
                <div className="formbtn">
                
                    <button type='submit' onClick={showRegister}>Register</button>
                </div>
            </form>
            
            <div className="rightfoot">
                <p className='footp'>Taking Story Telling to a New Level</p>
            </div>
        </div>

        <Routes>
            <Route path="/registered" element={<Register/>} />
        </Routes>
    </div>
  )
}

export default Home